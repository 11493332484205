@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,400i,700&amp;subset=cyrillic);
@charset "utf-8";


@font-face {
  font-family: 'a_FuturaRound_Bold';
  src: url('/fonts/a_FuturaRound_Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'HipsterScriptPro';
  src: url('/fonts/HipsterScriptPro.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
*:before,
*:after {
  box-sizing: border-box;
}
.clearfix:after,
.container:after {
  content: ' ';
  clear: both;
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
  font-size: 0;
}
html,
body {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased;
}
html {
  font: normal 16px/1.2 'Roboto', Arial, Helvetica, sans-serif;
  color: #000;
  letter-spacing: 3px;
}
body {
  text-align: center;
  background: #fff;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  max-height: 9001em;
}
fieldset,
form,
figure,
legend {
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
img {
  border: 0;
  vertical-align: top;
  max-width: 100%;
  height: auto;
}
mark {
  color: #000;
  background: #ff0;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
b,
strong {
  font-weight: bold;
}
hr {
  height: 0;
  box-sizing: content-box;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
dfn,
address {
  font-style: normal;
}


.jcf-file {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  background: none;
}

.jcf-real-element{outline: none;}

.jcf-file .jcf-real-element {
  position: absolute;
  font-size: 200px;
  height: 200px;
  margin: 0;
  right: 0;
  top: 0;
}

.jcf-file .jcf-fake-input {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
  overflow: hidden;
  border: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  padding: 5px 15px;
  line-height: 30px;
  color: #fff;
  background: rgba(255, 255, 255, .05);
  height: 43px;
  width: 270px;
  transition: all .2s linear;
}


.mac .columns-holder .column .jcf-file .jcf-fake-input {
  padding: 7px 15px 3px;
}

.jcf-file.jcf-disabled {
  background: none !important;
}

.jcf-file.jcf-disabled .jcf-fake-input {
  color: #fff;
}
.jcf-file.jcf-disabled:hover .jcf-fake-input {
  border-color: #fff;
}
.jcf-file .jcf-upload-button {
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 0 4px 4px 0;
  margin: 0 0 0 -2px;
  padding: 7px 16px;
  line-height: 30px;
  color: #0078a4;
  height: 43px;
  background: rgba(255, 255, 255, .9);
  transition: all .2s linear;
  display: none;
}

.mac .jcf-file .jcf-upload-button {
  padding: 9px 17px 5px;
}





/* Input */
input,
textarea,
select,
button,
.jcf-file .jcf-fake-input{
  font: normal 40px 'Roboto', Arial, Helvetica, sans-serif;
  color: #000;
  outline: none;
  line-height: normal;
  background: none;
  -webkit-appearance: none;
  box-shadow: none;
}
input,
textarea,
select,
.jcf-file .jcf-fake-input{
  margin: 0;
  border: 1px solid #d7d7d7;
  outline: none;
  border-radius: 10px;
  padding: 5px 43px;
  background: #d7d7d7;
  transition-duration: 0.3s;
  height: 104px;
  text-align: left;
  letter-spacing: 2.2px;
}
input,
textarea,
.jcf-file .jcf-fake-input {
  color: #fff;
}
input:focus,
textarea:focus,
.jcf-file.jcf-focus .jcf-fake-input{
  border-color: #000;
  background-color: #fff;
  color: #000;
}
.jcf-file{
  width: 100%;
}
.jcf-file .jcf-fake-input {
  line-height: 90px;
  width: 100%;
}


button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer;
}
button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner {
  padding: 0;
  border: 0 none;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  box-shadow: none;
}
input[type="checkbox"] {
  -webkit-appearance: checkbox;
}
input[type="radio"] {
  -webkit-appearance: radio;
}
input[type="checkbox"] + label,
input[type="radio"] + label {
  cursor: pointer;
}
::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}
::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
:-moz-placeholder {
  color: #fff;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}
textarea {
  resize: none;
  height: 312px;
}
[hidden],
template {
  display: none;
}
.input {
  margin-bottom: 54px;
}
.input input,
.input textarea,
.input select {
  width: 100%;
}
.input input[type="button"],
.input input[type="reset"],
.input input[type="submit"],
.input input[type="checkbox"],
.input input[type="radio"] {
  width: auto;
}
.label {
  text-transform: uppercase;
  color: #a5a4a4;
  font-size: 32px;
  display: block;
  margin: 0 0 24px;
  padding: 0;
  text-align: left;
  letter-spacing: 1px;
}
/* END Input */
/* Table */
table {
  width: 100%;
  border-collapse: collapse;
}
td,
th {
  vertical-align: middle;
  text-align: left;
  border: 1px solid #333;
  padding: 9px 10px;
}
.table-responsive {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
.table-responsive table {
  margin-bottom: 0 !important;
}
/* END Table */
article {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
p,
ul,
ol,
table,
form,
.table-responsive {
  margin: 0 0 18px;
}
ul,
ol {
  padding: 0 0 0 18px;
}
li {
  margin: 0 0 9px;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: bold;
  line-height: 1.2;
  font-size: 30px;
  color: #000;
  margin: 0 0 18px;
}
h2,
.h2 {
  font-size: 24px;
}
h3,
.h3 {
  font-size: 20px;
}
h4,
.h4 {
  font-size: 18px;
}
h5,
.h5 {
  font-size: 18px;
}
h6,
.h6 {
  font-size: 18px;
}
a {
  cursor: pointer;
  color: #000;
  text-decoration: underline;
  outline: none;
}
a:hover {
  text-decoration: underline;
}
.nowrap {
  white-space: nowrap;
}
.outtaHere {
  display: none;
  position: absolute;
  left: -10000px;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.flex,
.over_txt_hold,
.result_block_wrap .result_block_hold {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.half {
  width: 48%;
  flex-shrink: 0;
}
.body_hold {
  min-height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.container {
  width: 100%;
  padding: 0;
  margin: 0 auto;
  position: relative;
}
.content:after {
  content: ' ';
  clear: both;
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
  font-size: 0;
}
.video {
  width: 100%;
  margin: 0 auto 17px;
  overflow: hidden;
  text-align: center;
  position: relative;
  padding-bottom: 56%;
}
.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.btn {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  z-index: 1;
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: #fff !important;
  height: 86px;
  max-width: 100%;
  min-width: 414px;
  padding: 5px 30px 3px;
  border: 1px solid #287701;
  border-radius: 10px;
  background: #287701;
  transition-duration: 0.3s;
}
.btn:hover {
  text-decoration: none;
  color: #fff !important;
}
.btn:active {
  transform: translateY(4px);
}
.btn .btn_in {
  position: relative;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 1;
}
.btn_white {
  border: 2px solid #e6e6e6;
  background: none;
  height: 136px;
  font-weight: 100;
  font-size: 50px;
  text-transform: none;
  letter-spacing: 2.6px;
  padding: 3px 20px;
  border-radius: 20px;
}
.btn_message .btn_in {
  padding-left: 197px;
  overflow: visible;
}
.btn_message .btn_in:after {
  content: '';
  display: block;
  position: absolute;
  left: 53px;
  top: -10px;
  width: 105px;
  height: 73px;
  background: url("/img/icon1.png") no-repeat center;
  background-size: contain;
}
.btn_srch .btn_in {
  padding-left: 164px;
  overflow: visible;
}
.btn_srch .btn_in:after {
  content: '';
  display: block;
  position: absolute;
  left: 28px;
  top: -14px;
  width: 76px;
  height: 76px;
  background: url("/img/icon2.png") no-repeat center;
  background-size: contain;
}
/* end ripple_effect */
.green_txt {
  color: #287701 !important;
}
.logo {
  display: inline-block;
  text-decoration: none;
  font-family: 'a_FuturaRound_Bold', sans-serif;
  font-size: 220px;
  letter-spacing: 4.5px;
  margin: 125px 0 43px;
}
.logo:hover {
  text-decoration: none;
}
.main_img {
  display: inline-block;
  max-height: 1280px;
  margin: 11px 0 100px;
  overflow: hidden;
}
.main_img img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
}
.big_txt {
  font-size: 50px;
  margin-bottom: 20px;
}
.big_txt2 {
  font-size: 70px;
  font-style: italic;
  margin-bottom: 20px;
  letter-spacing: 1px;
}
.inp_block {
  display: block;
  line-height: 1;
  border-radius: 10px;
  font-size: 40px;
  color: #fff;
  background: #d7d7d7;
  border: 1px solid #d7d7d7;
  height: 104px;
  padding: 28px 20px 0 43px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  letter-spacing: 2.2px;
}
/* custom select */
.jcf-select {
  display: block;
  vertical-align: top;
  background: #d7d7d7;
  margin: 0;
  min-width: 1px;
  text-align: left;
  cursor: default;
  position: relative;
  line-height: 1;
  border-radius: 10px;
  font-size: 40px;
  letter-spacing: 2.2px;
  color: #fff;
  padding: 30px 90px 0 43px;
  border: 1px solid #d7d7d7 !important;
  width: 100%;
  height: 104px;
}
.jcf-select:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 58px;
  pointer-events: none;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #86939e transparent transparent transparent;
  margin-top: -2px;
}
.jcf-select select {
  z-index: 1;
  left: 0;
  top: 0;
}
.jcf-select .jcf-select-text {
  cursor: default;
  display: block;
  text-align: left;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.jcf-select .jcf-select-opener {
  display: none;
}
body > .jcf-select-drop {
  position: absolute;
  margin: -1px 0 0;
  z-index: 9999;
}
body > .jcf-select-drop.jcf-drop-flipped {
  margin: 1px 0 0;
}
.jcf-select .jcf-select-drop {
  position: absolute;
  margin-top: 0;
  z-index: 9999;
  top: 100%;
  left: -1px;
  right: -1px;
}
.jcf-select .jcf-drop-flipped {
  bottom: 100%;
  top: auto;
}
.jcf-select.jcf-compact-multiple {
  max-width: 220px;
}
.jcf-select.jcf-compact-multiple .jcf-select-opener:before {
  display: inline-block;
  padding-top: 2px;
  content: '...';
}
.jcf-select-drop .jcf-select-drop-content {
  border: none;
  padding: 1px 0 0;
}
.jcf-select-drop.jcf-compact-multiple .jcf-hover {
  background: none;
}
.jcf-select-drop.jcf-compact-multiple .jcf-selected {
  background: #e6e6e6;
  color: #000;
}
.jcf-select-drop.jcf-compact-multiple .jcf-selected:before {
  display: inline-block;
  content: '';
  height: 4px;
  width: 8px;
  margin: -7px 5px 0 -3px;
  border: 3px solid #777;
  border-width: 0 0 3px 3px;
  transform: rotate(-45deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.7071067811865483, M12=0.7071067811865467, M21=-0.7071067811865467, M22=0.7071067811865483, SizingMethod='auto expand')";
}
/* multiple select styles */
.jcf-list-box {
  overflow: hidden;
  display: inline-block;
  border: 1px solid #b8c3c9;
  min-width: 200px;
  margin: 0 15px;
}
/* select options styles */
.jcf-list {
  display: inline-block;
  vertical-align: top;
  position: relative;
  line-height: 14px;
  font-size: 12px;
  width: 100%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 0;
  overflow: hidden;
}
.jcf-list .jcf-list-content {
  vertical-align: top;
  display: inline-block;
  overflow: auto;
  width: 100%;
  /*max-height:250px !important;*/
}
.jcf-list ul {
  list-style: none;
  margin: 0;
  padding: 2px 0 0;
}
.jcf-list ul li {
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.jcf-list .jcf-overflow {
  overflow: auto;
}
.jcf-list .jcf-option {
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
  display: block;
  color: #333;
  text-decoration: none;
  text-overflow: ellipsis;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1;
  text-align: left;
  height: auto;
  min-height: 1px;
}
.jcf-list .jcf-disabled {
  background: #fff !important;
  color: #aaa !important;
}
.jcf-select-drop .jcf-hover,
.jcf-list-box .jcf-selected {
  background: #e6e6e6;
  color: #000;
}
.jcf-list .jcf-optgroup-caption {
  white-space: nowrap;
  font-weight: bold;
  display: block;
  padding: 5px 9px;
  cursor: default;
  color: #000;
}
.jcf-list .jcf-optgroup .jcf-option {
  padding-left: 30px;
}
/* scrollbars */
.jcf-select-drop .jcf-scrollable-wrapper {
  box-sizing: content-box;
  position: relative;
}
.jcf-select-drop .jcf-scrollbar-vertical {
  position: absolute;
  cursor: default;
  bottom: 0;
  right: 0;
  top: 0;
  background: none;
  width: 10px;
  padding: 0 0 0 2px;
}
.jcf-select-drop .jcf-scrollbar-vertical .jcf-scrollbar-dec,
.jcf-select-drop .jcf-scrollbar-vertical .jcf-scrollbar-inc,
.jcf-select-drop .jcf-scrollbar-horizontal .jcf-scrollbar-handle,
.jcf-select-drop .jcf-scrollbar-horizontal .jcf-scrollbar-slider,
.jcf-select-drop .jcf-scrollbar-horizontal .jcf-scrollbar-dec,
.jcf-select-drop .jcf-scrollbar-horizontal .jcf-scrollbar-inc,
.jcf-select-drop .jcf-scrollbar-horizontal {
  display: none;
}
.jcf-select-drop .jcf-scrollbar-vertical .jcf-scrollbar-handle {
  height: 1px;
  width: 6px;
  border-radius: 10px;
  background: #ccc;
}
.jcf-select-drop .jcf-scrollbar.jcf-inactive .jcf-scrollbar-handle {
  visibility: hidden;
}
/* end scrollbars */
.jcf-list-content .jcf-option-placeholder {
  display: none;
}
/* end custom select */
.vert_centr {
  margin-top: auto;
  margin-bottom: auto;
}
.form {
  width: 1072px;
  max-width: 100%;
  margin: 0 auto 20px;
}
.form2 {
  width: 100%;
  padding: 0 20px 30px;
}
.btn_hold {
  padding-top: 30px;
}
.form_title {
  text-align: center;
  font-weight: 100;
  font-size: 77px;
  margin-bottom: 160px;
  letter-spacing: 4px;
}
.centr_block {
  display: inline-block;
}
.custom_check_hold {
  display: block;
  margin-bottom: 156px;
  padding: 0 0 0 172px;
  position: relative;
  text-align: left;
  font-weight: 100;
  font-size: 100px;
  cursor: pointer;
  letter-spacing: 5.3px;
}
.custom_check_hold .radio_btn {
  display: block;
  border-radius: 50%;
  border: 2px solid #000;
  width: 98px;
  height: 98px;
  position: absolute;
  left: 5px;
  top: 6px;
}
.custom_check_hold .radio_btn:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 50%;
  background: #287701;
  border-radius: 50%;
  transition-duration: 0.3s;
}
.custom_check_hold input:checked + .radio_btn:after {
  margin: 10px;
}
.bg_grey {
  background: #f6f7f9;
}
.logo.logo_sm {
  font-size: 150px;
  letter-spacing: 3px;
  margin: 2px 0 4px;
}
.img_hold {
  height: 1678px;
  position: relative;
  overflow: hidden;
  border-radius: 34px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0 28px 42px;
}
.over_txt_hold {
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 0 20px 10px;
}
.over_txt_hold .over_txt {
  font-size: 80px;
  font-weight: 300;
  letter-spacing: normal;
  padding-right: 32px;
}
.over_txt_hold .btn {
  flex-shrink: 0;
}
.item_btn {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 203px;
  height: 203px;
  border-radius: 50%;
  border: 1px solid #d3d3d3;
  background: #fff no-repeat center;
  transition-duration: 0.3s;
  z-index: 2;
}
.item_btn:active {
  margin-top: 4px;
}
.item_btn:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  background: no-repeat center;
  background-size: contain;
}
.esc_btn {
  left: 55px;
}
.esc_btn:after {
  background-image: url(/img/icon_close.png);
  width: 88px;
  height: 80px;
  margin: -40px 0 0 -44px;
}
.like_btn {
  right: 55px;
}
.like_btn:after {
  background-image: url(/img/icon_like.png);
  width: 86px;
  height: 78px;
  margin: -34px 0 0 -43px;
}
.loc {
  display: block;
  position: relative;
  padding-left: 57px;
  font-family: Arial, sans-serif;
  font-size: 60px;
  line-height: 1;
  white-space: nowrap;
  font-weight: 400;
  margin: 10px 0 0 6px;
  letter-spacing: normal;
}
.loc:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
  width: 31px;
  height: 45px;
  background: url("/img/pin.png") no-repeat center;
  background-size: contain;
}
.img_hold_text {
  text-align: left;
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  max-width: 100%;
  font-size: 95px;
  font-weight: 700;
  color: #fff;
  letter-spacing: normal;
  padding: 0 20px 60px 55px;
}
.hide {
  display: none;
}
.result_block_wrap {
  background: #000;
  color: #fff;
  margin: 6px 0 49px;
  padding: 127px 20px 97px;
}
.result_block_wrap .result_title {
  font-family: 'HipsterScriptPro', Arial, sans-serif;
  font-size: 230px;
  line-height: 1.5;
  letter-spacing: normal;
  margin-bottom: 80px;
}
.result_block_wrap .result_block_cont {
  margin: 0 auto;
  width: 1104px;
  max-width: 100%;
}
.result_block_wrap .result_block_hold {
  align-items: center;
  margin-bottom: 138px;
}
.result_block_wrap .result_block {
  flex-shrink: 0;
  background: no-repeat center;
  background-size: cover;
  overflow: hidden;
  border-radius: 15px;
  position: relative;
  color: #fff;
  font-weight: 700;
  font-size: 38px;
  letter-spacing: normal;
  width: 446px;
  height: 500px;
}
.result_block_wrap .result_block_name {
  position: absolute;
  left: 46px;
  bottom: 30px;
  z-index: 1;
}
.result_block_wrap .result_block_icon {
  width: 115px;
  height: 104px;
  flex-shrink: 0;
  margin: 20px;
  background: url("/img/icon_like.png") no-repeat center;
  background-size: contain;
  position: relative;
  top: -12px;
}
.result_block_wrap .btn {
  width: 100%;
  margin-bottom: 55px;
}
.btn_message_hold {
  position: relative;
  z-index: 10;
}
.btn_message_hold .btn_message_text {
  font-size: 60px;
  font-weight: 700;
  position: absolute;
  bottom: calc(100% + 20px);
  left: 0;
  right: 0;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.btn_message_hold .btn_message_text .small {
  font-weight: 300;
  font-size: 40px;
}
.animate > .btn_message_text {
  animation: jump 2s linear;
  -webkit-animation: jump 2s linear;
}
.animate2 > .btn_message_text {
  animation: jumpLeft 2s linear;
  -webkit-animation: jumpLeft 2s linear;
}
@keyframes jump {
  0% {
    transform: translateX(0px) translateY(20px);
    opacity: 1;
  }
  40% {
    transform: translateX(0px) translateY(-10px);
  }
  50% {
    transform: translateX(20%) translateY(10px);
  }
  60% {
    transform: translateX(40%) translateY(-10px);
  }
  70% {
    transform: translateX(60%) translateY(20px);
  }
  80% {
    transform: translateX(80%) translateY(-10px);
  }
  90% {
    transform: translateX(100%) translateY(10px);
  }
  100% {
    transform: translateX(150%) translateY(0px);
    opacity: 1;
  }
}
@-webkit-keyframes jump {
  0% {
    transform: translateX(0px) translateY(20px);
    opacity: 1;
  }
  40% {
    transform: translateX(0px) translateY(-10px);
  }
  50% {
    transform: translateX(20%) translateY(10px);
  }
  60% {
    transform: translateX(40%) translateY(-10px);
  }
  70% {
    transform: translateX(60%) translateY(20px);
  }
  80% {
    transform: translateX(80%) translateY(-10px);
  }
  90% {
    transform: translateX(100%) translateY(10px);
  }
  100% {
    transform: translateX(150%) translateY(0px);
    opacity: 1;
  }
}
@keyframes jumpLeft {
  0% {
    transform: translateX(0px) translateY(20px);
    opacity: 1;
  }
  40% {
    transform: translateX(0px) translateY(-10px);
  }
  50% {
    transform: translateX(-20%) translateY(10px);
  }
  60% {
    transform: translateX(-40%) translateY(-10px);
  }
  70% {
    transform: translateX(-60%) translateY(20px);
  }
  80% {
    transform: translateX(-80%) translateY(-10px);
  }
  90% {
    transform: translateX(-100%) translateY(10px);
  }
  100% {
    transform: translateX(-150%) translateY(0px);
    opacity: 1;
  }
}
@-webkit-keyframes jumpLeft {
  0% {
    transform: translateX(0px) translateY(20px);
    opacity: 1;
  }
  40% {
    transform: translateX(0px) translateY(-10px);
  }
  50% {
    transform: translateX(-20%) translateY(10px);
  }
  60% {
    transform: translateX(-40%) translateY(-10px);
  }
  70% {
    transform: translateX(-60%) translateY(20px);
  }
  80% {
    transform: translateX(-80%) translateY(-10px);
  }
  90% {
    transform: translateX(-100%) translateY(10px);
  }
  100% {
    transform: translateX(-150%) translateY(0px);
    opacity: 1;
  }
}


.error {
  border-color: red;
}

.error-message {
  color: red;
  font-style: italic;
  margin-bottom: 1em;
}



  /*add 8.10.20*/
.item_btn{
  transform:translate(0, 0);
  margin-top:-102px !important;

}

.pumpAnim.item_btn{
  -webkit-animation:pump 600ms linear;
          animation:pump 600ms linear;
}

@keyframes pump{
  25%{
    transform:scale(0.7);
  }
  50%{
    transform:scale(1.2);
  }
  100%{
    transform:scale(1);
  }
}

@-webkit-keyframes pump{
  25%{
    transform:scale(0.7);
  }
  50%{
    transform:scale(1.2);
  }
  100%{
    transform:scale(1);
  }
}
